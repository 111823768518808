import { useMemo, useState, useEffect, useCallback } from 'react';
import Tooltip from '../components/Tooltip.jsx';
import { numLocaleOptionsFull, crv } from '../constants.js';
import config from '../constants.json';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { useNavigate, useLocation } from 'react-router-dom';
import { Span, Link, VSeparator, Opaque, HFlex, HTFlex, VFlex, BigLoader, Left, } from '../components/Layout';
import { observer } from 'mobx-react-lite';
import { getCoopAssetUSDPrices, sendVote } from '../algo.js';
import Wallet from '../components/Wallets.jsx';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { TxnId, TxnAddresses } from '../components/Txn.jsx';
import { TableRow as RTableRow, TableCell as RTableCell } from '../components/responsive-table.js'
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableBody from '@mui/material/TableBody';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import LinearProgress from '@mui/material/LinearProgress';
import useInterval from '../hooks/use-interval.js';
import { govStart, govEnd } from '../constants.js';
import laserBrownEye from '../images/laserbrowneye256.png';
import laserGoldenEye from '../images/goldeneye256.png';
import assets from '../assets.json';
import { openMany, printNumber, NFDCache } from '../utils.js';
import IconButton from '@mui/material/IconButton';
import ReplayIcon from '@mui/icons-material/Replay';
import DownloadIcon from '@mui/icons-material/Download';

function downloadFile(fileName, data) {
  const downloadLink = document.createElement('a');
  downloadLink.download = fileName;
  const url = URL.createObjectURL(data);
  downloadLink.href = url;
  downloadLink.click();
  URL.revokeObjectURL(url);
}

export async function exportToCsv(filename, data, asset) {
  const header = [
    'NFD or address',
    'wallet_amount',
    'vaulted_amount',
    'staked_cometa_amount',
    'total_amount',
    'percent_owned',
    'addresses',
  ].join(',')+"\r\n";
  const rows = data.map(([{ addresses, amount, total, farming, vaulted, perc }]) => {
    const [address] = addresses;
    let NFD = NFDCache[address];
    if (!(typeof NFD === 'string')) {
      NFD = address;
    }
    const amt = amount / (10 ** asset.decimals)
    const vlt = vaulted ? vaulted / (10 ** asset.decimals) : 0;
    const frm = farming ? farming / (10 ** asset.decimals) : 0;
    const tot = total / (10 ** asset.decimals);
    return [NFD, amt, vlt, frm, tot, perc, '"'+addresses.join(",")+'"'].join(',')
  }).join('\r\n');
  downloadFile(filename, new Blob([header, ...rows], { type: 'text/csv;charset=utf-8;' }));
}

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`cp-data-tabpanel-${index}`}
      aria-labelledby={`cp-data-tab-${index}`}
      {...other}
    >
      {value === index && (<>
          {children}
      </>)}
    </div>
  );
}

function a11yProps(_id) {
  const { id } = tabs.find(({id}) => id === _id);
  return {
    id,
    'aria-controls': `cp-tabpanel-${id}`,
  };
}

const tabs = assets.map(({aid, friendlyName}) => ({ id: aid, title: friendlyName, route: `/holders/#${aid}` }));

function Holders({ account, holders }) {
  const location = useLocation();
  const navigate = useNavigate();

  const value = useMemo(() => {
    const def = 0;
    const { hash } = location;
    if (hash && hash.startsWith('#')) {
      const idx = tabs.findIndex(tab => tab.route.endsWith(hash));
      return idx ?? def
    }
    return def;
  }, [location.hash]);

  const handleChange = (event, newValue) => {
    const { route } = tabs[newValue];
    navigate(route);
  };

  const [count, setCount] = useState('?');

  useEffect(() => {
    holders.refreshHolders();
  }, []);

  return <>
    <VSeparator id="data" />

    <Opaque>
      <Typography align="center" id="#table" variant="h2">COOP & LP Holders</Typography>
      <Box sx={{ maxWidth: { xs: 320, sm: 480, md: '100%', }}}>
        <Tabs
          value={value} 
          onChange={handleChange}
          variant="scrollable"
          scrollButtons="auto"
          >
          {tabs.map(({ id, title, }) =>
            <Tab key={`tabhead-${id}`} label={title} {...a11yProps(id)} />
          )}
        </Tabs>
      </Box>
    </Opaque>

    <VSeparator />

    { assets.map(({aid}, i) => <TabPanel value={value} key={`ass-${i}`} index={i}>
        <Inner aid={aid} holders={holders} loading={holders.loading} />
      </TabPanel>
    ) }

    <VSeparator />
  </>
}

const Inner = observer(({ aid, holders, loading }) => {
  const [show, setShow] = useState(100);

  const asset = useMemo(() => assets.find(({aid:a}) => a === aid), [aid]);

  const hasAnyData = !!holders.holders[aid]

  const actualHolders = useMemo(() => (!hasAnyData ? [] : Object.values(holders.holders[aid])
    .sort(([{total: a}], [{total: b}]) => a<b?1:-1)
  ), [hasAnyData, holders.holders && holders.holders[aid], aid]);

  const holderNum = actualHolders.length;
  const showHolders = useMemo(() => actualHolders.slice(0, show), [actualHolders, show]);
  const hasMore = showHolders.length !== actualHolders.length;
  const showMore = () => setShow(show => { const next = Math.min(show + 100, holderNum); return next });
  const showAll = () => setShow(holderNum);

  const refresh = useCallback(() => {
    holders.refreshHolders(1);
  }, [holders]);

  const doExport = useCallback(() => {
    const filename = ['holders',
      asset.friendlyName.replace(/[^A-Za-z]+/g, '-'),
      Date.now()
    ].join('-');
    exportToCsv(filename, actualHolders, asset);
  }, [actualHolders]);

  return <><Opaque sx={{pb: 2}}>
    <HFlex sx={{ position: 'relative', borderBottom: { xs: 'none', md: '1px var(--primary) solid' }, height: 75, justifyContent: 'flex-end', width: 1 }}>
      <Typography align="left" sx={{position: 'absolute', left: "50%", top: '50%', width: 0.75, textAlign: 'center', transform: 'translate(-50%, -50%)', }} variant="h3">
        {asset.friendlyName}<Typography variant="h3" sx={{ display: { xs: 'none', md: 'inline-block' }}}>&nbsp;Holders</Typography> {holderNum ? <>({actualHolders.length}) <Tooltip sx={{position: 'relative', left: -10, top: -3, mr: -3 }}>Holder count notes:<br />1/ Liquidity pools are excluded<br />2/ Vaults and their owners are counted as a single holder.<br/>3/ All addresses verified under the same NFD are merged.</Tooltip></> : ''}
      </Typography>
      <HFlex>
        <IconButton title="Reload data" disabled={loading} onClick={refresh}>
          <ReplayIcon className={loading ? "fastspin" : ""} />
        </IconButton>
        <IconButton title="Export (CSV)" disabled={loading} onClick={doExport}>
          <DownloadIcon />
        </IconButton>
      </HFlex>
    </HFlex>
    { !holderNum && holders.loading ? <Typography variant="h3" sx={{mt: 2}}>Loading</Typography> : <>
      <VFlex sx={{mb: 1, opacity: loading ? 0.5 : 1, width: { xs: 0.95, md: 0.8, xl: 0.7 }}}>
        <Table>
          <TableHead sx={{ display: { xs: 'none', md: 'table-header-group' } }}>
            <TableRow>
              <TableCell>Holder</TableCell>
              <TableCell>Amount</TableCell>
              <TableCell>Percentage</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
          { showHolders
              .map(([{addresses, vaultIDs, vaulted, farming, amount, perc}, ...rest], i) => {
                return <RTableRow key={`hjkldhjdx-${i}`}>
                  <RTableCell isx={{width: { xs: 1 }, justifyContent: 'flex-start'}}>
                    <Typography component="span" color="primary" className="bigtext">{i+1} &nbsp;</Typography>
                    <TxnAddresses addresses={addresses} />
                  </RTableCell>
                  <RTableCell isx={{ width: { xs: 1 } }}>
                  {printNumber(amount / (10 ** asset.decimals), '', true)}
                    { addresses[0] === crv ? <>&nbsp;🏦</> : null }
                    { farming ? <span title={`${printNumber(farming / (10 ** asset.decimals))} staked in Cometa`}>&nbsp;+&nbsp;({printNumber(farming / (10 ** asset.decimals))}🌱)</span> : null }
                    { vaulted ? <>&nbsp;+&nbsp;<Link title={`${printNumber(vaulted / (10 ** asset.decimals))} ${asset.friendlyName} vaulted in ${vaultIDs.length} Vestige vault${vaultIDs.length > 1 ? 's' : ''}`} href="#" onClick={() => openMany(vaultIDs.map(v => `https://vestige.fi/vault/${v}`))}>
                      ({printNumber(vaulted / (10 ** asset.decimals))}🔒){
                        vaultIDs.length > 1 ? `[${vaultIDs.length}]` : null}&nbsp;
                    </Link></> : null }
                    <Box sx={{display: { xs: 'inline-block', md: 'none'}}}>
                      &nbsp;({printNumber(perc)}%)
                    </Box>
                  </RTableCell>
                  <RTableCell sx={{display: { xs: 'none', md: 'table-cell' }}}>
                    {printNumber(perc)}%
                  </RTableCell>
                </RTableRow>
              })
          }
          </TableBody>
        </Table>
      </VFlex>
      { hasMore ? 
      <HFlex sx={{width: { xs: 0.9, sm: 0.8, md: 0.5 }, mt: 2, mb: 1}}>
        <Button sx={{mr: 2}} variant="outlined" onClick={showMore}>SHOW MORE</Button>
        <Button variant="outlined" onClick={showAll}>SHOW ALL</Button>
      </HFlex> : null }
    </>
    }
  </Opaque></>;
});

export default observer(Holders);

