import './Home.css';
import { useEffect } from 'react';
import { TeamHolder, VSeparator, Opaque, HFlex, VFlex, Left, Link } from '../components/Layout.jsx';
import { coopAid, ALGO, numLocaleOptionsFull, numLocaleOptions } from '../constants.js';
import { convertObjectSnakeCaseToCamelCase, printUSD } from '../utils.js';
import constants from '../constants.json';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { observer } from 'mobx-react-lite';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { useNavigate, } from 'react-router-dom';
import CopyButton from '../components/CopyButton.jsx';
import D13Icon from '../images/D13.png';
import Socials from '../components/Socials.jsx';
import CandlestickChartIcon from '@mui/icons-material/CandlestickChart';
import YouTubeIcon from '@mui/icons-material/YouTube';

const { rewardsAddress } = convertObjectSnakeCaseToCamelCase(constants);

function Info() {
  return <div className="info-container"> <InfoOutlinedIcon color="inherit" className="info" fontSize="inherit" /> </div>;
}

function SubTitle({ children, ...props }) {
  return <Typography align="center" variant="h2" sx={{mt: 4, mb: 1}}>{children}</Typography>;
}


function B({children}) {
  return <span className="maldini-bold">{children}</span>;
}

function H2({children}) {
  return <Typography variant="h2">{children}</Typography>;
}

function H3({children}) {
  return <Typography sx={{mb: 2}} variant="h3">{children}</Typography>;
}

const respMr = {
  mr: { xs: 0, md: 1},
  mt: 1,
}

export default observer(function({ donors }) {
  const navigate = useNavigate();

  useEffect(() => {
    donors.refreshDonors();
  }, []);

  return <>

    <VSeparator vh={2} />

    <Opaque h2 op={0.75}>
      <H2><B>COOP</B> COIN</H2>
    </Opaque>

    <VSeparator vh={1} />

    <Opaque h2 op={0.75}>
      <H2>On <B>{ALGO}lgorand</B></H2>
    </Opaque>

    <VSeparator vh={1} />


    <VSeparator vh={{xs: '27vh', md: '33vh' }}/>

    <Opaque sx={{ position: 'relative' }}>
      <VFlex>
        <H3><B>Coop coin</B> Genesis</H3>
        <Left>
          <Typography><strong>$COOP was created by Cooper Daniels</strong> and fully distributed as a surprise to anyone who had opted in.</Typography>
          <Typography>Until that time it was believed that each person would only be given a single $coop.</Typography>
          <Typography>This was not announced or expected. Cooper kept exactly <strong>zero</strong> $coop for himself.</Typography>
          <hr />
          <Typography><strong>It should have gone to zero and stayed there</strong>, as there was not even a token roadmap in place.</Typography>
          <Typography>But the Algorand commuinity reacted with interest.</Typography>
          <Typography>Liquidity Pools were created. Some paper-handed it, others gobbed it up.</Typography>
          <Typography>$coop was the talk of the town, and community members started building around it.</Typography>
        </Left>
        <HFlex sx={{mt: 1, flexDirection: { xs: 'column', sm: 'row' }}}>
          <CopyButton sx={respMr} changeLabel={false} copyIcon={true} initLabel={`ID ${coopAid}`} value={coopAid} />
          <Button sx={respMr} variant="outlined" startIcon={<CandlestickChartIcon />} onClick={() => window.location.href=`https://vestige.fi/asset/${coopAid}`}>VESTIGE.FI</Button>
          <Button sx={respMr} startIcon={<YouTubeIcon />} onClick={() => window.location.href=`https://www.youtube.com/@TheReCoop`}>THE RECOOP</Button>
        </HFlex>
      </VFlex>
    </Opaque>

    <VSeparator vh={{ xs: '5vh', md: '20vh'}} />
    <Opaque sx={{ position: 'relative' }}>
      <VFlex sx={{ maxWidth: '740px'}}>
        <H3><B>CRV</B> Community Rewards Vault</H3>
        <Left>
          <Typography>The CRV is a community initiative to pool $coop and liquidity pool tokens.{ donors.numDonations ? <> After {donors.numDonations} donations by {donors.data.length} community members, the CRV is currently worth around {printUSD(donors.totalUSD)}.</> : null }</Typography>
          <hr />
          <Typography><strong>This capital will be deployed towards bringing longevity and utility to $coop according to the decisions of a two-tier Governance.</strong></Typography>
          <hr />
          <Typography>The first tier is donors to the CRV, who can propose measures to be voted by the broader community and alter the mechanics of Challenge Governance.</Typography>
        </Left>
        <Button sx={{mt: 2}} onClick={() => navigate("/donations")} variant="outlined">CRV STATS & DONATIONS</Button>
      </VFlex>
    </Opaque>

    <VSeparator vh={{ xs: '5vh', md: '20vh'}} />
    <Opaque sx={{ position: 'relative' }}>
      <VFlex sx={{ maxWidth: '740px'}}>
        <H3>Challenge <B>Governance</B></H3>
        <Left>
          <Typography>The second tier of Governance is for the broader community of $COOP holders.</Typography>
          <Typography><strong>"Challenge Governance" is a gamified variant of Governance that rewards in $COOP.</strong></Typography>
          <hr />
          <Typography>Holders are called to vote on the Governance measures, as well as answer quiz-style challenge questions around $coop or Algorand lore.</Typography>
          <hr />
          <Typography>All votes will be counted, but only those who answer the challenges correctly will get rewarded. Challenge Governors may also intentionally forfeit their own rewards, thus growing others' reward share.</Typography>
        </Left>
        <Button sx={{mt: 2}} onClick={() => alert('Coming soon!')} variant="outlined">CHALLENGE GOVERNANCE</Button>
      </VFlex>
    </Opaque>

    <VSeparator vh={{ xs: '5vh', md: '20vh'}} />

    <Opaque id="socials">
      <H3>Get <B>Social</B></H3>
      <Socials />
    </Opaque>

    <VSeparator />
  </>;
});



     //  <!-- <Typography>Our white-paper outlining the design of CupStakes in more detail can be found <Link href="https://d13.co/uploads/temp/algofam/cupstakes.pdf">[here]</Link></Typography> -->
/*
    <VSeparator />

    <Opaque>
      <H3>Affiliations &amp; Endorsements</H3>
      <Left>
        <Typography>Our Trust partners act as individuals and their participation should not be considered an endorsement by their respective employers or organisations</Typography>
        <Typography>CupStakes is not affiliated with FIFA, the Algorand Foundation or Algorand inc.</Typography>
        <Typography>CupStakes does not misuse any registered trademarks.</Typography>
      </Left>
    </Opaque>
          */
