import { VSeparator, Opaque, HFlex, VFlex, Left, Link } from '../components/Layout.jsx';
import EmailIcon from '../images/email.png';
import GithubIcon from '../images/github.png';
import DiscordIcon from '../images/discord.png';
import TwitterIcon from '../images/twitter.png';

export default function({ mini, props, flip }) {
  let className = mini ? 'social social-mini' : 'social';
  return <HFlex {...props}>
    <Link href="https://discord.gg/JTeUBuV3qM"><img className={className} src={DiscordIcon} /></Link>
    <Link href="https://twitter.com/Coop_Daniels"><img className={className} src={TwitterIcon} /></Link>
    <Link href="https://twitter.com/D13_co"><img className={className+' flip'} src={TwitterIcon} /></Link>
    <Link href="mailto:head@coop.hair"><img className={className+' flip'} src={EmailIcon} /></Link>
  </HFlex>
}
