import { makeAutoObservable, reaction, runInAction } from 'mobx'
import { load, save } from './persist.js';
import { convertObjectSnakeCaseToCamelCase } from '../utils.js';
import constants from '../constants.json';
import get from 'lodash/get';
import { getDonations } from '../algo/indexer.js';
import assets from '../assets.json';
import { lookupNFD } from '../utils.js';

const _type = 'donors';

const DEBUG = false;

class Donors {
  lastUpdated = null;
  lastRound = null;
  loading = false;
  totals = null;
  totalUSD = null;
  numDonations = null;
  data = null;

  constructor(prices) {
    this.prices = prices;
    makeAutoObservable(this);

    load(_type, (val) => { 
      if (val?.data)
        setTimeout(() => {
          this.setData(val.data)
          this.setTotalUSD(val.totalUSD)
          this.setTotals(val.totals);
          this.setNumDonations(val.numDonations)
        }, 1);
    }, true);

    reaction(() => this.data, (newValue, prevValue) => {
      if (!save(_type, { data: this.data, totals: this.totals, numDonations: this.numDonations, totalUSD: this.totalUSD, v: 1 })) {
        console.error('err');
      }
    });
  }

  setTotals(val) {
    this.totals = val;
  }

  setTotalUSD(usd) {
    this.totalUSD = usd;
  }

  setNumDonations(val) {
    this.numDonations = val;
  }

  get timeSinceUpdate() {
    return Date.now() - this.lastUpdated;
  }

  setLoading(value) {
    this.loading = value;
  }

  setData(donors) {
    console.log("setting donors", donors);
    this.lastUpdated = Date.now();
    this.data = donors;
  }

  async refreshDonors() {
    if (this.loading) {
      console.log('already loading');
    }
    this.setLoading(true);

    const [prices, donations] = await Promise.all([
      this.prices.refreshPrices(),
      getDonations(),
    ]);

    let globalUSD = 0;
    let numDonations = 0;
    let totals = {};

    let donors = donations.reduce((donors, txn) => {
      const { "payment-transaction": ptxn, "asset-transfer-transaction": atxn } = txn;
      if (!atxn && !ptxn)
        return donors;
      const assetId = ptxn ? 0 : atxn['asset-id'];
      let amount = ptxn?.amount ?? atxn?.amount;
      const { id, "round-time": ts, } = txn;

      if (!amount) {
        return donors;
      }
      amount /= 1_000_000;
      const donor = txn.sender;
      if (!donors[donor]) {
        donors[donor] = scaffold();
      }

      const { assets, assetsUSD, txns } = donors[donor];

      if (!assets[assetId]) {
        assets[assetId] = 0;
      }
      if (!assetsUSD[assetId]) {
        assetsUSD[assetId] = 0;
      }
      if (!totals[assetId]) {
        totals[assetId] = 0;
      }
      assets[assetId] += amount;
      totals[assetId] += amount;

      numDonations++;

      const usd = amount * prices[assetId];
      assetsUSD[assetId] += usd;
      donors[donor].totalUSD += usd;

      globalUSD += usd;

      txns.push({ id, ts, amount, assetId });
      return donors;
    }, {});

    const NFDs = await lookupNFD(Object.keys(donors));

    donors = Object.values(
      Object.entries(donors).reduce((out, [address, obj]) => {
        const id = NFDs[address] ?? address;
        if (out[id]) {
          out[id] = mergeDonors(out[id], obj);
        } else {
          out[id] = obj;
        }
        const o = out[id];
        o.address = address;
        o.perc = o.totalUSD / globalUSD * 100;
        return out;
      }, {})
    ).sort((a, b) => a.totalUSD < b.totalUSD ? 1 : -1);

    // ORDER IMPORTANT, DATA LAST - hook to localStorage triggers on data
    this.setTotalUSD(globalUSD);
    this.setNumDonations(numDonations);
    this.setTotals(totals);
    this.setData(donors);
    this.setLoading(false);
  }
  
  reset() {
    this.setLastUpdated(null);
    this.setLoading(false);
    this.setData(null);
  }

}

export default Donors;

function scaffold() {
  return { assets: { }, assetsUSD: { }, totalUSD: 0, perc: 0, txns: [], };
}


function mergeDonors(obj1, obj2) {
  const out = scaffold();
  for(const field of ['assets', 'assetsUSD']) {
    for(const [aid1, amt1] of Object.entries(obj1[field])) {
      out[field][aid1] = obj1[field][aid1] + (obj2[field][aid1] ?? 0)
    }
    for(const [aid2, amt2] of Object.entries(obj2[field])) {
      if (out[field][aid2])
        continue;
      out[field][aid2] = obj2[field][aid2] + (obj1[field][aid2] ?? 0)
    }
  }
  out.totalUSD = obj1.totalUSD + obj2.totalUSD;
  out.txns = [...obj1.txns, ...obj2.txns];
  return out;
}
