import Tooltip from './Tooltip.jsx';
import { crv } from '../constants.js';
import { useState, useCallback, useEffect, useMemo } from 'react';
import { shorten, lookupNFD, openMany } from '../utils.js';
import ReceiptIcon from '@mui/icons-material/Receipt';
import { Link, VSeparator, Opaque, HFlex, VFlex, BigLoader } from '../components/Layout';
import SearchIcon from '@mui/icons-material/Search';
import { useNavigate } from 'react-router-dom';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import InsertLinkIcon from '@mui/icons-material/InsertLink';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';

export function TxnId({ id, gid, round, label, sx }) {
  const navigate = useNavigate();
  const url = useMemo(() => id ? `https://algoscan.app/tx/${id}` : `https://algoscan.app/tx/group/${encodeURIComponent(gid)}`, [id, gid]);
  const click = useCallback(() => {
    if (url.startsWith('http'))
      window.location = url
    else
      navigate(url)
  }, [url]);
  const display = useMemo(() => label ?? shorten(id ?? gid, 8, false), [id, label]);
  return <HFlex sx={sx}>{ (id || gid) ? <ReceiptIcon onClick={click} color="primary" sx={{ mr: 1}} /> : <InsertLinkIcon onClick={click} color="primary" sx={{mr: 1}}/>}<Link href={url}>{display}</Link></HFlex>
}

export function TxnAddresses({ addresses, sx, shortenL=10, shortenBoth=true, accountIcon=true }) {
  const navigate = useNavigate();
  const [address] = addresses;
  const shorta = shorten(address, shortenL, shortenBoth);
  const [display, setDisplay] = useState(shorta);
  const isCRV = addresses[0] === crv;
  const click = () => {
    if (isCRV) {
    } else {
      openMany(addresses.map(addr => `https://algoscan.app/address/${addr}`));
    }
    return false;
  }
  useEffect(() => {
    (async() => {
      const [address] = addresses;
      const res = await lookupNFD(address);
      if (res[address]) {
        let label = addresses.length > 1 ? ` (${addresses.length})` : '';
        const nfd = res[address];
        if (isCRV) {
          label = <Tooltip>Our rewards vault. Check the "CRV DONORS" page for details.</Tooltip>
        }
        setDisplay(<>{nfd}{label}</>);
      }
      else
        setDisplay(shorta);
    })();
  }, [address]);
  return <HFlex sx={sx}>{
    isCRV ? <AccountBalanceIcon className="fakeButton" onClick={click} color="primary" sx={{ mr: 1}} /> :
    accountIcon ? <AccountCircleIcon className="fakeButton" onClick={click} color="primary" sx={{ mr: 1}} /> : null }
    <Link href={isCRV ? "/donations" : ""} onClick={click}>{display}</Link></HFlex>
}

export function TxnAddress({ address, sx, noNFD, search=false, linkAlgoScan, shortenL=10, shortenBoth=true, accountIcon=true }) {
  const navigate = useNavigate();
  const shorta = shorten(address, shortenL, shortenBoth);
  const [display, setDisplay] = useState(shorta);
  const url = useMemo(() => linkAlgoScan ? `https://algoscan.app/address/${address}` : `/address/${address}`, [address, linkAlgoScan]);
  const click = useCallback(() => {
    if (url.startsWith('http'))
      window.location = url
    else
      navigate(url)
  }, [url]);
  useEffect(() => {
    (async() => {
      if (noNFD)
        return
      const res = await lookupNFD(address);
      if (res[address])
        setDisplay(res[address]);
      else
        setDisplay(shorta);
    })()
  }, [address, noNFD]);
  return <HFlex sx={sx}>{search ? <SearchIcon className="fakeButton" onClick={click} color="primary"/>:null}
    { accountIcon ? <AccountCircleIcon className="fakeButton" onClick={click} color="primary" sx={{ mr: 1}} /> : null }
    <Link href={url}>{display}</Link></HFlex>
}
